
import Vue, { PropType } from 'vue'
import { Broadcaster } from '~/models/broadcaster'
import { siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import { Speaker } from '~/models/speaker'
import SaLink from '~/components/_general/SaLink.vue'
import { SermonFilterCategories } from '~/assets/ts/utils/params'

export default Vue.extend({
  name: 'SiteSpeakerFromBroadcasterItem',
  components: { SaLink },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      required: true,
    },
    speaker: {
      type: Object as PropType<Speaker>,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedCount(): string {
      return this.count.toLocaleString()
    },
    url(): string {
      return siteBroadcasterUrl(
        this.broadcaster,
        `sermons/?${SermonFilterCategories.Speaker}=${this.speaker.id}`
      )
    },
    title(): string {
      return this.broadcaster.displayName
    },
  },
})
