import { render, staticRenderFns } from "./FromBroadcasterElement.vue?vue&type=template&id=024aaf34&scoped=true"
import script from "./FromBroadcasterElement.vue?vue&type=script&lang=ts"
export * from "./FromBroadcasterElement.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024aaf34",
  null
  
)

export default component.exports