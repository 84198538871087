
import Vue, { PropType } from 'vue'
import { Speaker } from '~/models/speaker'
import { Broadcaster } from '~/models/broadcaster'
import { BroadcasterDisplay } from '~/assets/ts/enums'
import { BroadcasterFromSpeaker } from '~/apiclient/apispeakers'
import BroadcasterElement from '~/components/_general/BroadcasterElement.vue'
import SiteSpeakerFromBroadcasterItem from '~/components/site/speaker/FromBroadcasterItem.vue'
import ShowMore from '~/components/_general/ShowMore.vue'

export interface SpeakerBroadcaster extends BroadcasterFromSpeaker {
  broadcaster: Broadcaster
}

export default Vue.extend({
  name: 'SiteSpeakerFromBroadcasterElement',
  components: { ShowMore, SiteSpeakerFromBroadcasterItem, BroadcasterElement },
  props: {
    speaker: {
      type: Object as PropType<Speaker>,
      required: true,
    },
  },
  data() {
    return {
      BroadcasterDisplay,
      broadcasters: [] as SpeakerBroadcaster[],
      open: false,
      aboveTheFoldCount: 3,
      mounted: false,
    }
  },
  computed: {
    primaryBroadcaster(): Broadcaster | undefined {
      const find = this.broadcasters.find((b) => b.isPrimarySpeaker)
      return find ? find.broadcaster : undefined
    },
    broadcastersAboveTheFold(): SpeakerBroadcaster[] {
      if (!this.broadcasters.length) return []
      return this.broadcasters.slice(0, this.aboveTheFoldCount)
    },
    broadcastersBelowTheFold(): SpeakerBroadcaster[] {
      if (this.broadcasters.length <= this.aboveTheFoldCount) return []
      return this.broadcasters.slice(
        this.aboveTheFoldCount,
        this.broadcasters.length
      )
    },
  },
  async mounted() {
    const broadcasters = await this.$apiClient.getBroadcastersFromSpeaker(
      this.speaker.id
    )
    broadcasters.forEach((b) => {
      this.broadcasters.push({
        ...b,
        broadcaster: new Broadcaster(b.broadcaster),
      })
    })
    this.mounted = true
  },
})
