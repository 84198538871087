
import Vue, { PropType } from 'vue'
import { Speaker } from '~/models/speaker'
import { SermonRequestOptions, SermonSortOptions } from '~/apiclient/apisermons'
import { siteSpeakerSermonsUrl } from '~/assets/ts/utils/urls'
import { SermonSortQsPair } from '~/components/sort/SermonsElement.vue'
import ShowMore from '~/components/_general/ShowMore.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import SiteSpeakerFromBroadcasterElement from '~/components/site/speaker/FromBroadcasterElement.vue'
import SiteSermonsFilteredListWithTitle from '~/components/site/sermons/FilteredListWithTitle.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

export default Vue.extend({
  components: {
    HorizontalRule,
    SiteSermonsFilteredListWithTitle,
    SiteSpeakerFromBroadcasterElement,
    MarkdownElement,
    ShowMore,
  },
  props: {
    speaker: {
      type: Object as PropType<Speaker>,
      required: true,
    },
  },
  computed: {
    options(): SermonRequestOptions {
      return {
        speakerID: this.speakerID,
        pageSize: 5,
        page: 1,
      }
    },
    bio(): string {
      return this.speaker?.bio ?? ''
    },
    popularSermonsOptions(): SermonRequestOptions {
      return {
        ...this.options,
        sortBy: SermonSortOptions.Downloads,
      } as SermonRequestOptions
    },
    speakerID(): number {
      return parseInt(this.$route.params.speaker)
    },
    speakerName(): string {
      return this.speaker.displayName
    },
    popularUrl(): string {
      return siteSpeakerSermonsUrl(this.speaker, [
        SermonSortQsPair(SermonSortOptions.Downloads),
      ])
    },
    newestUrl(): string {
      return siteSpeakerSermonsUrl(this.speaker, [
        SermonSortQsPair(SermonSortOptions.Newest),
      ])
    },
  },
})
